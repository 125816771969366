import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import { changeDateFormat } from '@ifca-root/react-component/src/helpers/Functions/dateFunction'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import {
  Avatar,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { KeyboardArrowRight, MoreVert } from '@material-ui/icons'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { GeneralDialog } from 'components/Dialog/GeneralDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  useCancelEinvoiceMutation,
  useDocumentListingLazyQuery,
  useDuplicateInvoiceMutation,
  useGeneratePaymentMutation,
  useGetApAllocationDocumentLazyQuery,
  useGetDocNumTitleQuery,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import {
  checkDueDate,
  formatDashDate,
  formatDate,
  formatTime,
  getDatePlusDay,
} from 'helpers/StringNumberFunction/FormatDate'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useParams } from 'react-router'
import { EInvoiceStatus } from '../EInvoiceComponent/EinvoiceStatusComponent'
import { useAPDeleteMutation } from '../Hooks/useAPDeleteMutation'
import { DateDialog } from 'components/Dialog/DateDialog'

export const APEntryList = (props: any) => {
  const {
    apSubmenu,
    filteredList,
    setOriginalListing,
    loading,
    userList,
    listStatus,
    setListStatus,
    createPermissionCheck,
    updatePermissionCheck,
    deletePermissionCheck,
    loadData,
    selectedStartDate,
    selectedEndDate,
  } = props
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))

  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()

  const GreyTooltip = withStyles({
    tooltip: { color: 'white', backgroundColor: '#9e9e9e' },
  })(Tooltip)

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any
  const [deleteDialog, setOpenDeleteDialog] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false)
  const [docNoChecking, setDocNoChecking] = useState(true)
  const [paymentID, setPaymentID] = useState('')
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [dateDialog, setOpenDateDialog] = useState(false)

  const { handleDelete, mutationLoading, deleteCalled } = useAPDeleteMutation({
    apSubmenu: apSubmenu,
    loadData: loadData,
    CompanyID: CompanyID,
    setOriginalListing: setOriginalListing,
    setOpenDeleteDialog: setOpenDeleteDialog,
  })

  const [
    generatePayment,
    {
      loading: generatePaymentLoading,
      called: generatePaymentCalled,
      error: generatePaymentError,
    },
  ] = useGeneratePaymentMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: ({ generatePayment }) => {
      setOriginalListing([])
      loadData({
        variables: { CompanyID: CompanyID, StatusArr: 'COMPLETED' },
      })
      setOpenDateDialog(false)
      setOpenDialog(true)
      setPaymentID(generatePayment.PaymentID)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
    },
  })

  const [
    cancelEinvoice,
    {
      loading: CancelEinvoiceLoading,
      called: CancelEinvoiceCalled,
      error: CancelEinvoiceError,
    },
  ] = useCancelEinvoiceMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: ({ cancelEinvoice }) => {
      setOpenCancelDialog(false)
      loadData({
        variables: { CompanyID: CompanyID, StatusArr: 'COMPLETED' },
      })
    },
  })
  const [
    duplicateInvoice,
    {
      loading: duplicateInvoiceLoading,
      called: duplicateInvoiceCalled,
      error: duplicateInvoiceError,
    },
  ] = useDuplicateInvoiceMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: ({ duplicateInvoice }) => {
      setOpenDuplicateDialog(false)
      setListStatus('ACTIVE')
      loadData({
        variables: { CompanyID: CompanyID, StatusArr: 'ACTIVE' },
      })
    },
  })

  /* -------------------------------------------- */
  /*                  ATTACHMENT                  */
  /* -------------------------------------------- */
  const {
    files,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const [
    loadDoc,
    { loading: DocLoad, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      if (!menu?.obj?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
  })

  useEffect(() => {
    if (!DocLoad) {
      loadDoc({ variables: { refID: ID } })
    }
  }, [menu?.obj?.Attachment[0]])

  let ref, title, keyID, ID, clickText, refTable
  switch (apSubmenu) {
    case 'advance':
      title = 'Advance'
      ref = { AdvanceID: menu?.ID }
      keyID = 'AdvanceID'
      ID = menu?.obj?.AdvanceID
      refTable = 'AP_Advance'
      clickText = true
      break
    case 'invoice':
      title = 'Invoice'
      ref = { InvoiceID: menu?.ID }
      keyID = 'InvoiceID'
      ID = menu?.obj?.InvoiceID
      refTable = 'AP_Invoice'
      clickText = false
      break
    case 'refund':
      title = 'Refund'
      ref = { RefundID: menu?.ID }
      keyID = 'RefundID'
      ID = menu?.obj?.RefundID
      refTable = 'AP_Refund'
      clickText = true
      break
    case 'debit-note':
      title = 'Debit Note'
      ref = { DebitNoteID: menu?.ID }
      keyID = 'DebitNoteID'
      ID = menu?.obj?.DebitNoteID
      refTable = 'AP_DebitNote'
      clickText = true
      break
    case 'creditor-debit-note':
      title = 'Debit Note from Creditor'
      ref = { CreditorDebitNoteID: menu?.ID }
      keyID = 'CreditorDebitNoteID'
      ID = menu?.obj?.CreditorDebitNoteID
      refTable = 'AP_CreditorDebitNote'
      clickText = true
      break
    case 'payment':
      title = 'Payment'
      ref = { PaymentID: menu?.ID }
      keyID = 'PaymentID'
      ID = menu?.obj?.PaymentID
      refTable = 'AP_Payment'
      clickText = true
      break
    case 'credit-note':
      title = 'Credit Note'
      ref = { CreditNoteID: menu?.ID }
      keyID = 'CreditNoteID'
      ID = menu?.obj?.CreditNoteID
      refTable = 'AP_CreditNote'
      clickText = true
      break
    case 'creditor-credit-note':
      title = 'Credit Note from Creditor'
      ref = { CreditorCreditNoteID: menu?.ID }
      keyID = 'CreditorCreditNoteID'
      ID = menu?.obj?.CreditorCreditNoteID
      refTable = 'AP_CreditorCreditNote'
      clickText = false
      break
    case 'self-billed':
      title = 'Self-Billed'
      ref = { SelfBilledID: menu?.ID }
      keyID = 'SelfBilledID'
      ID = menu?.obj?.SelfBilledID
      refTable = 'AP_SelfBilled'
      clickText = false
      break
  }

  const [
    loadAllocationDocument,
    {
      loading: allocationDocumentLoading,
      error: getAllocationDocumentError,
      data: { getAllocationDocument } = { getAllocationDocument: [] },
    },
  ] = useGetApAllocationDocumentLazyQuery({
    fetchPolicy: 'network-only',

    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
  })

  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RefTable: refTable,
    },
  })

  useEffect(() => {
    if (
      (apSubmenu === 'invoice' && getDocumentNumberHeader?.length === 0) ||
      (apSubmenu === 'invoice' &&
        (getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === false) ||
          !getDocumentNumberHeader)) ||
      getDocumentNumberHeader?.find(
        x => x?.IsAutoDocNo === true && x?.SampleOutput !== ''
      ) ||
      apSubmenu === 'creditor-credit-note' ||
      apSubmenu === 'creditor-debit-note'
    ) {
      return setDocNoChecking(false)
    } else if (
      apSubmenu === 'invoice' &&
      getDocumentNumberHeader?.find(
        x => x?.IsAutoDocNo === true && x?.SampleOutput === ''
      )
    ) {
      return setDocNoChecking(true)
    }
  }, [getDocumentNumberHeader])

  const handleRenderAlloc = e => {
    if (apSubmenu === 'advance') {
      loadAllocationDocument({
        variables: {
          entityID: [e?.AdvanceID],
          companyID: CompanyID,
          refTable: 'AP_Advance',
          creditorDebtorID: e?.CreditorAccountID,
        },
      })
    } else {
      return e
    }
  }

  const handleGeneratePayment = data => {
    generatePayment({
      variables: {
        input: {
          CompanyID: CompanyID,
          AdvanceID: menu?.obj?.AdvanceID,
          DocNo: menu?.obj?.DocNo,
          DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
          TransactionDate: formatDashDate(
            new Date(data?.DocDate)?.toISOString()
          ),
          RefNo: menu?.obj?.RefNo,
          CreditorAccountID: menu?.obj?.CreditorAccountID,
          PaymentMethodID: menu?.obj?.PaymentMethodID,
          CreditCardID: menu?.obj?.CreditCardID,
          ChequeNo: menu?.obj?.ChequeNo,
          ChequeDate: menu?.obj?.ChequeDate,
          ChequeExpiryDate: !!menu?.obj?.ChequeDate
            ? changeDateFormat(
                getDatePlusDay(menu?.obj?.ChequeDate, 180),
                'DD MMM YYYY'
              )
            : null,
          BankAccountID: menu?.obj?.BankAccountID,
          Description: menu?.obj?.Description,
          Remark: menu?.obj?.Remark,
          ApprovalStatus: ApprovalStatus.Completed,
          Amount: menu?.obj?.DocAmt,
          DocAmt: menu?.obj?.DocAmt,
          Attachment: files,
        },
      },
    })
  }

  const handleCancel = data => {
    cancelEinvoice({
      variables: {
        SelfBilledID: data?.SelfBilledID,
        CompanyID: CompanyID,
      },
    })
  }
  const handleDuplicate = data => {
    duplicateInvoice({
      variables: {
        InvoiceID: data?.InvoiceID,
      },
    })
  }
  //to check whether advance have been refunded or not
  const refundChecking = menu?.obj?.Allocation?.find(
    x => x?.CreditRefTable === 'AP_Refund'
  )

  return (
    <>
      {mutationLoading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      {CancelEinvoiceLoading && <Loading />}
      {duplicateInvoiceLoading && <Loading />}

      <ContentWrapper float overflow>
        <List className="core-list">
          {advancedFilterList(filteredList) === undefined ||
          advancedFilterList(filteredList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                loadData({
                  variables: {
                    CompanyID: CompanyID,
                    StatusArr: [listStatus],
                    skip: filteredList?.length,
                    take: 30,
                    searchValue:
                      getSearch?.name === '' || !getSearch?.name
                        ? undefined
                        : getSearch?.name,
                    startDate:
                      selectedStartDate === null
                        ? undefined
                        : selectedStartDate,
                    endDate:
                      selectedEndDate === null ? undefined : selectedEndDate,
                  },
                })
              }}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                loading && (
                  <div style={{ textAlign: 'center' }}>
                    {' '}
                    <CircularProgress />{' '}
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {advancedFilterList(filteredList)?.map((el, index) => {
                let refundAlloc = el?.Allocation?.find(
                  x => x?.CreditRefTable === 'AP_Refund'
                )

                return (
                  <ListItem
                    key={index}
                    style={{
                      paddingBottom: listStatus === 'COMPLETED' ? '25px' : null,
                    }}
                  >
                    <ListItemText
                      style={{
                        textDecoration:
                          el?.ApprovalStatus === 'CANCELLED' && 'line-through',
                      }}
                      primary={
                        <>
                          <span className="desc date-width">
                            {formatDate(
                              apSubmenu == 'self-billed'
                                ? el?.DocDate
                                : el?.einvoice_self_billed_date ?? el?.DocDate
                            )}
                          </span>
                          <span
                            style={
                              apSubmenu == 'self-billed' &&
                              el?.einvoice?.einvoice_cancelled
                                ? { color: 'red' }
                                : null
                            }
                            className={`mdLabel flex-space
                            ${
                              clickText && listStatus === 'COMPLETED'
                                ? 'click-text'
                                : ''
                            }`}
                            onClick={() => {
                              if (
                                el?.ApprovalStatus === ApprovalStatus.Completed
                              ) {
                                if (apSubmenu === 'advance') {
                                  return history.push({
                                    pathname: `/account-payable/${CompanyID}/advance/${el?.AdvanceID}/preview`,
                                    state: { ...el, item: el?.APAdvanceItem },
                                  })
                                } else if (apSubmenu === 'credit-note') {
                                  return history.push({
                                    pathname: `/account-payable/${CompanyID}/credit-note/${el?.CreditNoteID}/preview`,
                                    state: {
                                      ...el,
                                      allocation: el?.Allocation,
                                      mainCreditOrDebit: 'Credit',
                                    },
                                  })
                                } else if (apSubmenu === 'debit-note') {
                                  return history.push({
                                    pathname: `/account-payable/${CompanyID}/debit-note/${el?.DebitNoteID}/preview`,
                                    state: {
                                      ...el,
                                      allocation: el?.Allocation,
                                      mainCreditOrDebit: 'Debit',
                                    },
                                  })
                                } else if (apSubmenu === 'payment') {
                                  return history.push({
                                    pathname: `/account-payable/${CompanyID}/payment/${el?.PaymentID}/preview`,
                                    state: {
                                      ...el,
                                      allocation: el?.Allocation,
                                    },
                                  })
                                } else if (apSubmenu === 'refund') {
                                  return history.push({
                                    pathname: `/account-payable/${CompanyID}/refund/${el?.RefundID}/preview`,
                                    state: {
                                      ...el,
                                      allocation: el?.Allocation,
                                    },
                                  })
                                }
                              }
                            }}
                          >
                            {el?.DocNo || el?.RefNo}
                          </span>

                          <span>
                            <IconText
                              font="highlight-text xxTitle"
                              parentStyle={{ color: '#ff9800' }}
                              children={amtStr(el?.DocAmt)}
                            />
                          </span>
                        </>
                      }
                      secondary={
                        <Grid container className="desc flex-space text-noflow">
                          {listStatus === 'COMPLETED' &&
                          apSubmenu !== 'advance' &&
                          apSubmenu !== 'self-billed' ? (
                            <Grid
                              spacing={1}
                              container
                              className="table-content"
                            >
                              <Grid item xs={8}>
                                <span style={{ fontSize: '10px' }}>
                                  {el?.Allocation?.map(
                                    alloc =>
                                      alloc?.[`${alloc?.CreditOrDebit}DocNo`]
                                  )?.join(', ') ?? '-'}
                                </span>
                              </Grid>
                              <Grid item xs={4}>
                                <IconText
                                  parentClassName="c-grey"
                                  parentStyle={{ justifyContent: 'end' }}
                                  font="c-grey xxTitle"
                                  children={amtStr(el?.BalanceAmt)}
                                />
                              </Grid>
                            </Grid>
                          ) : apSubmenu === 'self-billed' ? (
                            <>
                              <span className="desc text-noflow">
                                {!!el?.APLedger?.DocNo ? (
                                  <>
                                    <span className="xxTitle">
                                      {el?.APLedger?.RefTable}{' '}
                                    </span>
                                    <span>{el?.APLedger?.DocNo} </span>
                                  </>
                                ) : (
                                  <span className="xxTitle">
                                    {el?.einvoice_trx_type === 'CREDIT_NOTE'
                                      ? 'Self-Billed Credit Note'
                                      : 'Self-Billed Invoice'}{' '}
                                  </span>
                                )}
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                style={{ fontSize: '10px' }}
                                className="desc  text-noflow"
                              >
                                {!!el?.APPayment?.[0]?.DocDate
                                  ? formatDate(el?.APPayment?.[0]?.DocDate)
                                  : formatDate(refundAlloc?.CreditDocDate) ??
                                    '' ??
                                    '-'}
                              </span>
                              <span
                                style={{ marginLeft: '20px' }}
                                className={`mdLabel flex-space`}
                              >
                                {refundAlloc?.CreditDocNo ?? ''}
                              </span>
                            </>
                          )}

                          <Grid spacing={1} container className="table-content">
                            <Grid item xs={!!el?.DueDate ? 9 : 12}>
                              <span style={{ fontSize: '10px' }}>
                                {el?.CreditorAccount?.CompanyName}
                              </span>
                            </Grid>
                            {!!el?.DueDate && (
                              <Grid item xs={3} style={{ textAlign: 'end' }}>
                                <span
                                  className="desc"
                                  style={{
                                    color: checkDueDate(el?.DueDate)
                                      ? 'red'
                                      : null,
                                  }}
                                >
                                  {formatDate(el?.DueDate)}
                                </span>
                              </Grid>
                            )}
                          </Grid>

                          <Grid spacing={1} container className="table-content">
                            <Grid item xs={11}>
                              <div className="icon-text">
                                <GreyTooltip
                                  disableFocusListener
                                  title={
                                    <Fragment>
                                      <Grid container wrap="nowrap" spacing={2}>
                                        <Grid item>
                                          <Avatar
                                            style={{
                                              width: '20px',
                                              height: '20px',
                                            }}
                                          >
                                            <PersonIcon
                                              style={{ fontSize: 'small' }}
                                            />
                                          </Avatar>
                                        </Grid>
                                        <Grid item xs zeroMinWidth>
                                          <div style={{ fontSize: '12px' }}>
                                            {listStatus === 'ACTIVE'
                                              ? el?.ProfileInfo?.name
                                              : userList?.find(
                                                  x => x?.ID === el?.approvedBy
                                                )?.name}
                                          </div>
                                          <div style={{ fontSize: '10px' }}>
                                            {`${formatDate(
                                              el?.approvedTs ??
                                                new Date(
                                                  el?.createdTs
                                                ).toISOString()
                                            )} ${formatTime(
                                              el?.modTs ??
                                                new Date(
                                                  el?.createdTs
                                                ).toISOString()
                                            )}`}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Fragment>
                                  }
                                  arrow
                                  enterTouchDelay={0}
                                >
                                  <img
                                    src={SubmitterLog}
                                    style={{
                                      width: '12px',
                                      marginRight: '3px',
                                    }}
                                  />
                                </GreyTooltip>
                                <span className="desc text-noflow">
                                  {el?.Description}
                                </span>
                              </div>
                            </Grid>
                            <Grid item xs={1}>
                              {el?.einvoice_self_billed && (
                                <EInvoiceStatus
                                  isEinvoice={el?.einvoice_self_billed}
                                  einvoiceStatus={el?.einvoice_status}
                                  data={el}
                                  isPost={
                                    listStatus === 'ACTIVE' ||
                                    listStatus === 'REJECTED'
                                  }
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                    />
                    <ListItemSecondaryAction>
                      {listStatus === 'ACTIVE' ||
                      listStatus === 'REJECTED' ||
                      (listStatus === 'COMPLETED' &&
                        apSubmenu === 'self-billed') ||
                      apSubmenu === 'invoice' ? (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          aria-controls="menu-list"
                          aria-haspopup="true"
                          onClick={e => handleClick(e, el?.[keyID], index, el)}
                          style={
                            listStatus === 'ACTIVE' || listStatus === 'REJECTED'
                              ? {}
                              : { verticalAlign: '30px' }
                          }
                        >
                          <MoreVert />
                        </IconButton>
                      ) : listStatus === 'COMPLETED' &&
                        (apSubmenu === 'advance' ||
                          apSubmenu === 'credit-note' ||
                          apSubmenu === 'creditor-credit-note' ||
                          apSubmenu === 'payment') ? (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          aria-controls="menu-list"
                          aria-haspopup="true"
                          onClick={e => handleClick(e, el?.[keyID], index, el)}
                          style={
                            listStatus === 'ACTIVE' || listStatus === 'REJECTED'
                              ? {}
                              : { verticalAlign: '30px' }
                          }
                        >
                          <MoreVert onClick={() => handleRenderAlloc(el)} />
                        </IconButton>
                      ) : (
                        <IconButton edge="end" aria-label="arrow">
                          <KeyboardArrowRight
                            onClick={() =>
                              history.push({
                                pathname: `/account-payable/${CompanyID}/${apSubmenu}/${el?.[keyID]}/detail`,
                                state: el,
                              })
                            }
                          />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </InfiniteScroll>
          )}
        </List>
        {listStatus === 'ACTIVE' && (
          <FloatButton
            disabled={handlePermDisabled({
              permEnum: createPermissionCheck,
            })}
            onClick={() => {
              if (docNoChecking) {
                setOpenSnackBar(true)
                setSnackBarMsg(SystemMsgs.errorNumberingStructure())
              } else {
                history.push(`/account-payable/${CompanyID}/${apSubmenu}/add`)
              }
            }}
          />
        )}
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {listStatus === 'ACTIVE' ? (
          <>
            <MenuItem
              disabled={
                !CompanyID
                  ? true
                  : handlePermDisabled({
                      permEnum: updatePermissionCheck,
                    })
              }
              onClick={() => {
                history.push({
                  pathname: `/account-payable/${CompanyID}/${apSubmenu}/${menu?.ID}/edit`,
                  state: menu?.obj,
                })
              }}
            >
              <span className="">Edit</span>
            </MenuItem>
            <MenuItem
              disabled={handlePermDisabled({
                permEnum: deletePermissionCheck,
              })}
              onClick={() => {
                setOpenDeleteDialog(true)
              }}
            >
              <span className="">Delete</span>
            </MenuItem>
          </>
        ) : listStatus === 'COMPLETED' &&
          (apSubmenu === 'credit-note' ||
            apSubmenu === 'creditor-credit-note' ||
            apSubmenu === 'payment') ? (
          <>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: `/account-payable/${CompanyID}/${apSubmenu}/${ID}/detail`,
                  state: menu?.obj,
                })
              }}
            >
              <span className="">View</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: `/account-payable/${CompanyID}/${apSubmenu}/${ID}/allocation`,
                  state: menu?.obj,
                })
              }}
            >
              <span className="">Allocation</span>
            </MenuItem>
          </>
        ) : listStatus === 'REJECTED' ? (
          <MenuItem
            disabled={
              !CompanyID
                ? true
                : handlePermDisabled({
                    permEnum: updatePermissionCheck,
                  })
            }
            onClick={() => {
              history.push({
                pathname: `/account-payable/${CompanyID}/${apSubmenu}/${menu?.ID}/edit`,
                state: { ...menu?.obj, mode: 'resubmit' },
              })
            }}
          >
            <span className="">Resubmit</span>
          </MenuItem>
        ) : listStatus === 'COMPLETED' && apSubmenu === 'advance' ? (
          <>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: `/account-payable/${CompanyID}/${apSubmenu}/${ID}/detail`,
                  state: menu?.obj,
                })
              }}
            >
              <span className="">View</span>
            </MenuItem>
            {getAllocationDocument?.length > 0 &&
              !menu?.obj?.PaymentID &&
              !refundChecking && (
                <MenuItem
                  onClick={() => {
                    setOpenDateDialog(true)
                  }}
                >
                  <span className="">Generate to Payment</span>
                </MenuItem>
              )}
          </>
        ) : listStatus === 'COMPLETED' &&
          (apSubmenu === 'self-billed' || apSubmenu === 'invoice') ? (
          <>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: `/account-payable/${CompanyID}/${apSubmenu}/${ID}/detail`,
                  state: menu?.obj,
                })
              }}
            >
              <span className="">View</span>
            </MenuItem>
            {menu?.obj?.einvoice?.einvoice_cancelled &&
              apSubmenu === 'self-billed' && (
                <MenuItem
                  onClick={() => {
                    setOpenCancelDialog(true)
                  }}
                >
                  <span className="">Cancel</span>
                </MenuItem>
              )}
            {apSubmenu === 'invoice' && (
              <MenuItem
                disabled={
                  !CompanyID
                    ? true
                    : handlePermDisabled({
                        permEnum: AcctPermission.AccPayableInvoicesDuplicate,
                      })
                }
                onClick={() => {
                  setOpenDuplicateDialog(true)
                }}
              >
                <span className="">Duplicate</span>
              </MenuItem>
            )}
          </>
        ) : null}
      </Menu>

      <DeleteDialog
        ID={ref}
        openDeleteDialog={deleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        title={title}
        menu={menu?.obj}
        handleDelete={handleDelete}
      />
      {!generatePaymentLoading && (
        <GeneralDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          title={'Generate Payment'}
          body={'Proceed to allocation payment ?'}
          docNo={menu?.obj?.DocNo}
          onConfirm={() => {
            history.push({
              pathname: `/account-payable/${CompanyID}/payment/${paymentID}/allocation`,
              state: menu?.obj,
            })
          }}
          loadData={loadData}
          CompanyID={CompanyID}
          // hasInfo={hasInfo}
        />
      )}

      <GeneralDialog
        openDialog={openCancelDialog}
        setOpenDialog={setOpenCancelDialog}
        title={`Cancel Invoice/Credit Note`}
        body={
          'Proceed to cancel this transaction? This action will result in a double-entry reversal if any.'
        }
        docNo={menu?.obj?.DocNo}
        onConfirm={() => {
          handleCancel(menu?.obj)
        }}
        loadData={loadData}
        CompanyID={CompanyID}
        // hasInfo={hasInfo}
      />

      <GeneralDialog
        openDialog={openDuplicateDialog}
        setOpenDialog={setOpenDuplicateDialog}
        title={`Duplicate Invoice`}
        body={`Proceed duplicate invoice for ${menu?.obj?.DocNo}.`}
        docNo={menu?.obj?.DocNo}
        onConfirm={() => {
          handleDuplicate(menu?.obj)
        }}
        loadData={loadData}
        CompanyID={CompanyID}
        // hasInfo={hasInfo}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />

      <DateDialog
        onSubmit={handleGeneratePayment}
        openDialog={dateDialog}
        setOpenDialog={setOpenDateDialog}
        CompanyID={CompanyID}
      />
    </>
  )
}
