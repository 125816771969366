import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'
import {
  useGetCompanyNameQuery,
  useGetMasterCoaForFormQuery,
  useGetDepartmentListingByCoaLazyQuery,
} from 'generated/graphql'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { uuid } from 'uuidv4'
import * as yup from 'yup'

interface PaymentItemProps {
  MasterCOAID: string
  DocAmt: number
  CostCentreID: string
  Remark: string
}

const defaultVal: any = {
  MasterCOAID: '',
  DocAmt: 0,
  CostCentreID: '',
}

export const JournalItems = (props: any) => {
  const {
    openJournalItem,
    setOpenJournalItem,
    CompanyID,
    journalItemData,
    JournalProcessingItemID,
    formMode,
    detailMode,
    menu,
  } = props

  let form, mode
  switch (detailMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Edit'
      mode = 'edit'
  }

  /* -------------------------------------------- */
  /*                   USE FORM                   */
  /* -------------------------------------------- */
  const JournalItemSchema = yup.object().shape({
    MasterCOAID: CommonYupValidation.requireField(
      'Expense Account is required'
    ),
    CostCentreID: CommonYupValidation.requireField('Department is required'),
    Remark: CommonYupValidation.requireField('Remark is required'),
    DocAmt: yup
      .string()
      .notOneOf([0, '0', '0.00', '-0', '-0.00'], 'Amount cannot be 0')
      .required('Amount is required'),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    reset,
    watch,
  } = useForm<PaymentItemProps>({
    defaultValues: {
      MasterCOAID:
        detailMode === 'new' || detailMode === 'add'
          ? undefined
          : menu?.obj?.MasterCOAID ?? '',
      DocAmt:
        detailMode === 'new' || detailMode === 'add'
          ? 0
          : menu?.obj?.DocAmt ?? 0,
      Remark: menu?.obj?.Remark ?? journalItemData[0]?.Remark ?? '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(JournalItemSchema),
  })

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [docAmt, setDocAmt] = useState(
    amtNumStr(JournalProcessingItemID && journalItemData?.DocAmt) ?? 0
  )
  const [record, setRecord] = useState(false)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [mountDefVal, setMountDefVal] = useState(!!menu?.obj)
  const [voiceRemark, setVoiceRemark] = useState('')

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */
  //Company
  const {
    loading: CompanyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
  })

  const {
    loading: MasterCOALoading,
    data: { getMasterCOAForForm } = { getMasterCOAForForm: [] },
  } = useGetMasterCoaForFormQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onCompleted: ({ getMasterCOAForForm }) => {
      if (getMasterCOAForForm?.length > 0) {
        if (detailMode === 'edit') {
          let accountType = getMasterCOAForForm?.find(
            x => x?.MasterCOAID === menu?.obj?.MasterCOAID
          )?.AccountType

          fetchDepartmentListingByItemID({
            variables: {
              CompanyID,
              MasterCOAID: menu?.obj?.MasterCOAID,
              AccountType: accountType,
            },
          })
        }
      }
    },
  })

  const [
    fetchDepartmentListingByItemID,
    {
      loading: HandlerLoading,
      data: { getDepartmentListingByCOA } = { getDepartmentListingByCOA: [] },
    },
  ] = useGetDepartmentListingByCoaLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getDepartmentListingByCOA }) => {
      if (
        getDepartmentListingByCOA?.length === 1 &&
        !!getDepartmentListingByCOA[0]?.IsDefault
      ) {
        setValue('CostCentreID', getDepartmentListingByCOA[0]?.CostCentreID)
      }
      if (!!menu?.obj?.CostCentreID && mountDefVal === true) {
        setValue('CostCentreID', menu?.obj?.CostCentreID)
      }
    },
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(`${errorMessage}"`)
    },
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */
  const handleAmtChange = event => {
    setDocAmt(event.value)
  }

  const [countItem, setCountItem] = useState(0)

  const onSubmit = (data, mode, index) => {
    if (detailMode === 'add') {
      journalItemData.push({
        Sequence: journalItemData?.length + 1,
        JournalProcessingID: data?.JournalProcessingID,
        JournalProcessingItemID: uuid(),
        MasterCOAID: data?.MasterCOAID,
        MasterCOACode: getMasterCOAForForm.filter(
          coa => coa?.MasterCOAID === data?.MasterCOAID
        )[0]?.Code,
        MasterCOAName: getMasterCOAForForm.filter(
          coa => coa?.MasterCOAID === data?.MasterCOAID
        )[0]?.Name,
        DocAmt: parseFloat(amtNumStr(data?.DocAmt)),
        CostCentreID: data.CostCentreID,
        CostCentreCode: getDepartmentListingByCOA.find(
          ccc => ccc?.CostCentreID === data.CostCentreID
        )?.Code,
        CostCentreName: getDepartmentListingByCOA.find(
          ccc => ccc?.CostCentreID === data.CostCentreID
        )?.Name,
        Remark: data?.Remark,
      })
    } else if (detailMode === 'edit' && formMode === 'edit') {
      let temp = {
        Sequence: journalItemData?.Sequence ?? index + 1,
        JournalProcessingID: data?.JournalProcessingID,
        JournalProcessingItemID: data?.JournalProcessingItemID,
        MasterCOAID: data?.MasterCOAID,
        MasterCOACode: getMasterCOAForForm.find(
          coa => coa?.MasterCOAID === data?.MasterCOAID
        )?.Code,
        MasterCOAName: getMasterCOAForForm.find(
          coa => coa?.MasterCOAID === data?.MasterCOAID
        )?.Name,
        DocAmt: !!parseFloat(amtNumStr(data?.DocAmt))
          ? parseFloat(amtNumStr(data?.DocAmt))
          : null,
        CostCentreID: data?.CostCentreID,
        CostCentreCode: getDepartmentListingByCOA.find(
          ccc => ccc?.CostCentreID === data.CostCentreID
        )?.Code,
        CostCentreName: getDepartmentListingByCOA.find(
          ccc => ccc?.CostCentreID === data.CostCentreID
        )?.Name,
        Remark: data?.Remark,
      }

      journalItemData?.splice(index, 1, temp)
    } else {
      journalItemData[index] = {
        Sequence: journalItemData?.Sequence ?? index + 1,
        JournalProcessingID: data?.JournalProcessingID,
        JournalProcessingItemID: data?.JournalProcessingItemID,
        MasterCOAID: data?.MasterCOAID,
        MasterCOACode: getMasterCOAForForm.filter(
          coa => coa?.MasterCOAID === data?.MasterCOAID
        )[0]?.Code,
        MasterCOAName: getMasterCOAForForm.filter(
          coa => coa?.MasterCOAID === data?.MasterCOAID
        )[0]?.Name,
        DocAmt: !!parseFloat(amtNumStr(data?.DocAmt))
          ? parseFloat(amtNumStr(data?.DocAmt))
          : null,
        CostCentreID: data?.CostCentreID,
        CostCentreCode: getDepartmentListingByCOA.find(
          ccc => ccc?.CostCentreID === data.CostCentreID
        )?.Code,
        CostCentreName: getDepartmentListingByCOA.find(
          ccc => ccc?.CostCentreID === data.CostCentreID
        )?.Name,
        Remark: data?.Remark,
      }
    }

    if (mode === 'new') {
      reset(defaultVal)
      setCountItem(countItem + 1)
      setOpenJournalItem(false)
      setOpenJournalItem(true)
    } else {
      setOpenJournalItem(false)
    }
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {HandlerLoading && <Loading />}
      {MasterCOALoading && <Loading />}
      <CommonDialog
        fullWidth={true}
        open={openJournalItem}
        onClose={() => {
          setOpenJournalItem(false)
        }}
        sections={{
          header: {
            title: 'Journal Detail',
            rightText: detailMode === 'add' ? 'New' : 'Edit',
          },
          body: () => (
            <>
              <div
                className="content-container"
                style={{ marginTop: '-20px', display: 'block' }}
              >
                <CardContents>
                  {!MasterCOALoading && (
                    <Controller
                      render={({ value, onChange }) => {
                        const defVal = getMasterCOAForForm?.filter(
                          coa => coa?.MasterCOAID === watch('MasterCOAID')
                        )[0]
                        const selectedCOA = getMasterCOAForForm?.filter(
                          coa => coa?.MasterCOAID === value
                        )[0]
                        return (
                          <Autocomplete
                            key={countItem}
                            options={
                              getMasterCOAForForm?.sort((a, b) => {
                                return a?.Code?.localeCompare(b?.Code)
                              }) || []
                            }
                            getOptionLabel={option => {
                              return `${option?.Code} | ${option?.Name}`
                            }}
                            fullWidth
                            onChange={(value, newValue: any) => {
                              onChange(newValue?.MasterCOAID)
                              setMountDefVal(false)
                              setValue('CostCentreID', null)
                              fetchDepartmentListingByItemID({
                                variables: {
                                  CompanyID,
                                  MasterCOAID: newValue?.MasterCOAID,
                                  AccountType: newValue?.AccountType,
                                },
                              })
                            }}
                            defaultValue={defVal}
                            disabled={mode === 'approve-reject'}
                            PopperComponent={AccCodeDropdownFullWidth}
                            renderOption={(props, option) => {
                              return (
                                <div>
                                  <div>
                                    <span className="xsTitle">
                                      {props?.Code}
                                    </span>
                                  </div>
                                  <div className="desc">{props?.Name}</div>
                                </div>
                              )
                            }}
                            renderInput={(params: any) => {
                              return (
                                <div>
                                  <TextField
                                    {...params}
                                    helperText={errors?.MasterCOAID?.message}
                                    error={errors?.MasterCOAID ? true : false}
                                    label="Account Code *"
                                    style={{ width: '100%' }}
                                    disabled={mode === 'approve-reject'}
                                    InputLabelProps={
                                      selectedCOA ? { shrink: true } : null
                                    }
                                    margin="normal"
                                    variant="standard"
                                  />
                                </div>
                              )
                            }}
                          />
                        )
                      }}
                      label="Account Code "
                      name="MasterCOAID"
                      autoComplete="off"
                      control={control}
                      multiline={true}
                      fullWidth
                      margin="normal"
                      ref={register}
                      helperText={errors?.MasterCOAID?.message}
                      error={errors?.MasterCOAID ? true : false}
                      required
                      disabled={formMode === 'approve-reject' ? true : false}
                    />
                  )}
                  <Controller
                    render={({ value, onChange }) => {
                      const defVal =
                        getDepartmentListingByCOA?.find(
                          x => x?.CostCentreID === watch('CostCentreID')
                        ) ?? null
                      return (
                        <Autocomplete
                          options={getDepartmentListingByCOA || []}
                          getOptionLabel={option =>
                            option ? `${option?.Code} | ${option?.Name}` : ''
                          }
                          fullWidth
                          onChange={(value, newValue: any) => {
                            onChange(newValue?.CostCentreID)
                          }}
                          disabled={formMode === 'approve-reject'}
                          disableClearable={
                            !!(
                              getDepartmentListingByCOA?.length === 1 &&
                              getDepartmentListingByCOA[0]?.IsDefault === true
                            )
                          }
                          renderOption={(props, option) => {
                            return (
                              <div>
                                <div>
                                  <span className="xsTitle">{props?.Code}</span>
                                </div>
                                <div className="desc">{props?.Name}</div>
                              </div>
                            )
                          }}
                          value={
                            !!getDepartmentListingByCOA[0]?.IsDefault
                              ? getDepartmentListingByCOA[0]
                              : defVal
                          }
                          renderInput={(params: any) => {
                            return (
                              <div>
                                <TextField
                                  {...params}
                                  helperText={
                                    errors?.CostCentreID
                                      ? 'Department is required'
                                      : ''
                                  }
                                  error={errors?.CostCentreID ? true : false}
                                  label="Department"
                                  style={{ width: '100%' }}
                                  margin="dense"
                                  disabled={
                                    !!(
                                      getDepartmentListingByCOA?.length === 1 &&
                                      getDepartmentListingByCOA[0]
                                        ?.IsDefault === true
                                    )
                                  }
                                  required
                                />
                              </div>
                            )
                          }}
                        />
                      )
                    }}
                    label="Department"
                    name="CostCentreID"
                    autoComplete="off"
                    control={control}
                    multiline={true}
                    fullWidth
                    margin="dense"
                    ref={register}
                    helperText={errors?.CostCentreID?.message}
                    error={errors?.CostCentreID ? true : false}
                    disabled={
                      !!(
                        getDepartmentListingByCOA?.length === 1 &&
                        getDepartmentListingByCOA?.find(
                          x => x?.CostCentreID === watch('CostCentreID')
                        )?.IsDefault === true
                      )
                    }
                    required
                  />
                  <Controller
                    as={<NumberFormat />}
                    thousandSeparator
                    customInput={TextField}
                    id="standard-basic"
                    name="DocAmt"
                    label="Amount"
                    value={docAmt}
                    autoComplete="off"
                    control={control}
                    onValueChange={e => {
                      handleAmtChange(e)
                    }}
                    decimalScale={2}
                    fixedDecimalScale
                    margin="normal"
                    required
                    helperText={errors?.DocAmt?.message}
                    error={errors?.DocAmt ? true : false}
                    ref={register}
                    disabled={formMode === 'approve-reject' ? true : false}
                  />
                  <VoiceTextField
                    mounted={true}
                    label="Remark"
                    name="Remark"
                    required
                    value={voiceRemark}
                    setValue={setValue}
                    record={record}
                    setRecord={setRecord}
                    control={control}
                    controllerProps={{
                      error: errors?.Remark ? true : false,
                      helperText: errors?.Remark?.message,
                      ref: register,
                      autoComplete: 'off',
                    }}
                  />
                </CardContents>
              </div>
              <ErrorDialog
                errorDia={errorDia}
                setErrorDia={setErrorDia}
                errorMsg={errMsg}
                errorHeaderMsg={'Error!'}
              />
            </>
          ),
          footer: {
            actions:
              detailMode === 'add'
                ? [
                    {
                      displayText: 'Close',
                      props: {
                        onClick: () => setOpenJournalItem(false),
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm & New',
                      props: {
                        onClick: () => {
                          handleSubmit(data =>
                            onSubmit(data, 'new', menu?.index)
                          )()
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm',
                      props: {
                        onClick: () => {
                          handleSubmit(data =>
                            onSubmit(data, 'save', menu?.index)
                          )()
                        },

                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                  ]
                : [
                    {
                      displayText: 'Close',
                      props: {
                        onClick: () => setOpenJournalItem(false),
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                    {
                      displayText: 'Confirm',
                      props: {
                        onClick: () => {
                          handleSubmit(data =>
                            onSubmit(data, 'save', menu?.index)
                          )()
                        },
                        variant: 'contained',
                        color: 'primary',
                      },
                    },
                  ],
          },
        }}
      />
    </>
  )
}
