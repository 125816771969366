import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import {
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  useMediaQuery,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import BigNumber from 'bignumber.js'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import {
  AddFavoriteDialog,
  DeleteFavoriteDialog,
} from 'components/Favorite/FavoriteDialog'
import FavoriteMenu from 'components/Favorite/FavoriteMenu'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'
import { getTotalAmt } from 'containers/ARAPModule/Helper/AmountCalculation'
import { dueDateChecker } from 'containers/ARAPModule/Helper/DateCalculation'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  DocDateValidationAfter,
  DocDateValidationBefore,
} from 'containers/CashBookModule/DocDateValidation'
import {
  AcctPermission,
  ApprovalStatus,
  DocumentListingDocument,
  GetArFavoriteInvoiceDocument,
  GetArInvoiceDocument,
  GetArInvoicebyStatusDocument,
  RecordStatus,
  useCreateArFavoriteInvoiceMutation,
  useCreateArInvoiceMutation,
  useDeleteArFavoriteInvoiceMutation,
  useDocumentListingLazyQuery,
  useGetArFavoriteInvoiceQuery,
  useGetBillItemListingQuery,
  useGetBillItemQuery,
  useGetCompanyNameQuery,
  useGetCostCentreCodeLazyQuery,
  useGetCostCentreLazyQuery,
  useGetDebtorAccountQuery,
  useGetDocNumTitleQuery,
  useGetDocumentDateValidationQuery,
  useGetMasterCoaQuery,
  useGetTaxEffectiveDateQuery,
  useGetTaxSchemeQuery,
  useLatestOpenPeriodCheckingDateQuery,
  useUpdateArInvoiceMutation,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDashDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router'
import { uuid } from 'uuidv4'
import * as yup from 'yup'

interface ARInvoiceProps {
  //Invoice
  DocDate: string
  TrxDate: string
  DebtorAccountID: string
  Description: string
  Remark: string
  InvoiceID: string
  DocAmt: number
  RefNo: string
}

interface ARInvoiceItemProps {
  BillItemID: string
  CostCentreID: string
  Amount: number
  TaxSchemeID: string
  TaxAmt: number
  TaxRate: number
  DocAmt: number
  Remark: string
}

interface FavInvoiceProps {
  Name: string
}

export const InvoiceStateForm = (props: any) => {
  const { CompanyID, InvoiceID, InvoiceItemID }: any = useParams()
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const editData = location?.state as any
  const [taxEffectiveList, setTaxEffectiveList] = useState<any>()
  const [invoiceItemData, setInvoiceItemData] = useState([])
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(SnackBarContext)
  const [checkInvoiceItemError, setCheckInvoiceItemError] = useState([])
  const [onSub, setSub] = useState(false)
  const [initDocs, setInitDocs] = useState([])
  const [openExitConf, setOpenExitConf] = useState(null)
  const [openFavoriteDialog, setOpenFavoriteDialog] = useState(false)
  const [openFavoriteDeleteDialog, setOpenFavoriteDeleteDialog] = useState(
    false
  )
  const [record, setRecord] = useState(false)
  const [voiceRemark, setVoiceRemark] = useState('')
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [edit, setEdit] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const { formMode } = props

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()

  const {
    anchorEl: anchorElFav,
    setAnchorEl: setAnchorElFav,
    menu: menuFav,
    handleClick: handleClickFav,
    handleClose: handleCloseFav,
  } = useMenuOption()

  const InvoiceFormSchema = yup.object().shape({
    DebtorAccountID: CommonYupValidation.requireField(
      'Debtor Name is required'
    ),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    DocDate: CommonYupValidation.requireField('Doc Date is Required'),
    TrxDate: CommonYupValidation.requireField('Transaction Date is Required'),
    RefNo: CommonYupValidation.requireField('Reference No is Required'),
  })

  const InvoiceItemFormSchema = yup.object().shape({
    BillItemID: CommonYupValidation.requireField('Bill Item is required'),
    CostCentreID: CommonYupValidation.requireField('Department is required'),
    Amount: yup
      .string()
      .required('Amount is required')
      .test('sumZero', 'Amount should not be zero', val => {
        return parseFloat(amtNumStr(val)) !== 0
      }),
    Remark: CommonYupValidation.requireField('Remark is required'),
  })

  const FavInvoiceFormSchema = yup.object().shape({
    Name: CommonYupValidation.requireField(SystemMsgs.name()),
  })

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
    clearErrors,
    reset,
    formState: { isSubmitted },
  } = useForm<ARInvoiceProps>({
    defaultValues: {
      Remark: editData ? editData?.Remark : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(InvoiceFormSchema),
  })

  const {
    handleSubmit: handleSubmitItem,
    register: registerItem,
    errors: errorsItem,
    control: controlItem,
    getValues: getValuesItem,
    watch: watchItem,
    setValue: setValueItem,
    clearErrors: clearErrorsItem,
    reset: resetItem,
  } = useForm<ARInvoiceItemProps>({
    mode: 'onSubmit',
    resolver: yupResolver(InvoiceItemFormSchema),
  })

  const {
    handleSubmit: handleFavSubmit,
    register: favRegister,
    control: favControl,
    errors: favErrors,
    watch: favWatch,
  } = useForm<FavInvoiceProps>({
    mode: 'onSubmit',
    resolver: yupResolver(FavInvoiceFormSchema),
  })

  /* -------------------------------------------- */
  /*                    QUERY                     */
  /* -------------------------------------------- */

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
    onCompleted: ({ getCompany }) => {
      loadDefaultCostCentre(getCompany[0]?.DefaultCostCentre)
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: TaxLoading,
    error: TaxError,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    onCompleted: () => {},
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: DocDateValidationLoading,
    error: DocDateValidationError,
    data: { getDocumentDateValidation } = { getDocumentDateValidation: [] },
  } = useGetDocumentDateValidationQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RefTable: 'AR_Invoice',
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: DebtorAccountLoading,
    error: DebtorAccountError,
    data: { getDebtorAccount } = { getDebtorAccount: [] },
  } = useGetDebtorAccountQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: masterCOALoading,
    error: masterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Name' },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  let debtor = getDebtorAccount.find(
    x => x?.DebtorAccountID === editData?.DebtorAccountID
  )

  const [term, setTerm] = useState(
    mode === 'edit' ? editData?.DebtorAccount?.CreditTerm : 0
  )

  const [selectedBillItem, setSelectedBillItem] = useState(null)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [storeGetItem, setStoreGetItem] = useState(false)

  const {
    loading: billItemLoading,
    error: billItemError,
    data: { getBillItem } = { getBillItem: [] },
  } = useGetBillItemListingQuery({
    fetchPolicy: 'network-only',
    variables: { orderByAsc: 'Name', RecordStatus: RecordStatus.Active },
    onCompleted: data => {
      setStoreGetItem(true)
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const docDateTimestamp = new Date(watch('DocDate'))
  const yearDocDate = docDateTimestamp.getFullYear()
  const monthDocDate = (docDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayDocDate = docDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const transferDate = `${yearDocDate}-${monthDocDate}-${dayDocDate}`

  const trxDateTimestamp = new Date(watch('TrxDate'))
  const yearTrxDate = trxDateTimestamp.getFullYear()
  const monthTrxDate = (trxDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayTrxDate = trxDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const trxDate = `${yearTrxDate}-${monthTrxDate}-${dayTrxDate}`

  const openPeriod1 =
    transferDate <= latestOpenPeriodCheckingDate?.StartDate ||
    transferDate >= latestOpenPeriodCheckingDate?.EndDate

  const openPeriod2 =
    trxDate >= latestOpenPeriodCheckingDate?.StartDate &&
    trxDate <= latestOpenPeriodCheckingDate?.EndDate

  const checkingYearClose1 = openPeriod1 ? true : false
  const checkingYearClose2 = openPeriod2 ? false : true

  const {
    loading: TaxEffectiveDateLoading,
    error: TaxEffectiveDateError,
    data: { getTaxEffectiveDate } = { getTaxEffectiveDate: [] },
  } = useGetTaxEffectiveDateQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (getTaxEffectiveDate?.length > 0) {
        let temp = getTaxEffectiveDate?.filter(
          tax =>
            Number(new Date(tax?.Date)) <
              DocDateValidationAfter(
                getDocumentDateValidation?.map(x => x?.MonthsAfter)
              ) &&
            Number(new Date(tax?.Date)) >
              DocDateValidationBefore(
                getDocumentDateValidation?.map(x => x?.MonthsBefore)
              )
        )
        setTaxEffectiveList(temp)
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: FavoriteInvoiceLoading,
    error: FavoriteInvoiceError,
    data: { getARFavoriteInvoice } = {
      getARFavoriteInvoice: [],
    },
  } = useGetArFavoriteInvoiceQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID, UserID: user?.ID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const favNames = getARFavoriteInvoice?.map(fav => fav?.Name)

  const [
    fetchCostCentre,
    {
      loading: CostCentreLoading,
      error: CostCentreErrors,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreCodeLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  /* -------------------------------------------- */
  /*                   USEEFECT                   */
  /* -------------------------------------------- */

  useEffect(() => {
    if (formMode === 'edit' && editData) {
      const InvoiceItems = editData?.ARInvoiceItem?.map((el, index) => {
        return {
          InvoiceItemID: el?.InvoiceItemID,
          BillItemName: el?.BillItem?.Name,
          BillItemID: el?.BillItemID,
          CostCentreName: el?.CostCentre?.Name,
          CostCentreID: el?.CostCentreID,
          Amount: el?.Amount,
          TaxSchemeID: el?.TaxScheme?.TaxSchemeID,
          TaxCode: el?.TaxScheme?.Code,
          TaxAmt: el?.TaxAmt,
          TaxRate: el?.TaxRate,
          Remark: el?.Remark,
          DocAmt: el?.DocAmt,
        }
      })
      invoiceItemData.push(...InvoiceItems)
    }
  }, [formMode, editData])

  useEffect(() => {
    if (!documentLoading && mode !== 'add') {
      fetchDocuments({ variables: { refID: editData?.InvoiceID } })
    }

    if (editData?.files) {
      setFiles(editData?.files)
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file)
        })
      )
    }
  }, [mode, editData?.InvoiceID, editData])

  useEffect(() => {
    if (!!storeGetItem && !!menu?.obj?.BillItemID) {
      const findCoa = getBillItem
        ?.filter(x => {
          return x?.BillItemID === menu?.obj?.BillItemID
        })
        .map(y => {
          return y?.MasterCOAID
        })

      const tempCoa = getMasterCOA?.find(x => x?.MasterCOAID === findCoa[0])

      setAccountType(tempCoa?.AccountType)
      if (
        tempCoa?.AccountType === 'ASSET' ||
        tempCoa?.AccountType === 'LIABILITY' ||
        tempCoa?.AccountType === 'EQUITY'
      ) {
        setValueItem('CostCentreID', defaultCostCentre?.CostCentreID)
        setValueItem('CostCentreName', defaultCostCentre?.Name)
      } else {
        fetchCostCentre({
          variables: {
            CompanyID: CompanyID,
            MasterCOAID: findCoa[0],
            IsLastNode: true,
          },
        })
      }
    }
  }, [storeGetItem, menu?.obj])

  useEffect(() => {
    if (editData?.Attachment) {
      setInitDocs(editData?.Attachment)
    }
  }, [editData])

  const taxScheme: any = getTaxScheme

  useEffect(() => {
    if (!!editData && !!taxScheme) {
      // set tax on edit
      setSelectedTax(
        taxScheme?.find(x => x?.TaxSchemeID === editData?.TaxSchemeID)
      )

      setTaxRate(
        taxScheme
          .filter(x => x?.TaxSchemeID === editData?.TaxSchemeID)[0]
          ?.TaxEffective?.filter(
            x => new Date(x?.Date) <= new Date(taxEffectiveDate)
          )
          .reduce((a, b) => {
            return new Date(a.Date) > new Date(b.Date) ? a : b
          })?.Rate
      )
    }
  }, [editData?.InvoiceItemID, taxScheme])

  /* -------------------------------------------- */
  /*                  FUNCTION                    */
  /* -------------------------------------------- */

  let statusInput = formMode => {
    let temp
    switch (formMode) {
      case 'submit':
        temp = ApprovalStatus.Submit
        break
      case 'draft':
        temp = ApprovalStatus.Active
        break
      case 'approve':
        temp = ApprovalStatus.Approved
        break
      case 'reject':
        temp = ApprovalStatus.Rejected
        break
    }
    return temp
  }

  let totalAmount = invoiceItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.DocAmt),
    0
  )

  let totalTaxAmt = invoiceItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.TaxAmt),
    0
  )

  let amtBeforeTax = totalAmount - totalTaxAmt

  let calcTotal = items => {
    return items?.reduce(
      (total, currentValue) => (total = total + currentValue.DocAmt),
      0
    )
  }

  const handleAddFavorite = () => {
    setOpenFavoriteDialog(true)
  }

  const handleSelectFavorite = data => {
    const tempFavItemArr = data?.FavoriteInvoiceItem?.map(x => {
      return {
        InvoiceItemID: uuid(),
        BillItemID: x?.BillItemID,
        RevenueCode: x?.MasterCOA?.Code,
        RevenueName: x?.MasterCOA?.Name,
        CostCentreID: x?.CostCentreID,
        CostCentreCode: x?.CostCentre?.Name,
        TaxSchemeID: x?.TaxSchemeID,
        TaxCode: x?.TaxScheme?.Code,
        TaxRate: parseFloat(x?.TaxRate),
        TaxAmt: parseFloat(x?.TaxAmt),
        Amount: parseFloat(x?.Amount),
        DocAmt: parseFloat(x?.DocAmt),
        Sequence: x?.Sequence,
        Remark: x?.Remark,
      }
    })
    setValue('DebtorAccountID', data?.DebtorAccountID)
    setValue('Remark', data?.Remark)
    setValue('DocAmt', data?.DocAmt)
    setValue('Description', data?.Description)
  }

  const updateNewInvoiceItem = (newDate, TEList) => {
    const arr = []
    invoiceItemData?.map(x => {
      let NewTax =
        getTaxEffectiveDate?.filter(
          j =>
            j?.TaxSchemeID === x?.TaxSchemeID &&
            new Date(j?.Date) <= new Date(newDate)
        )?.length > 0
          ? getTaxEffectiveDate
              ?.filter(
                j =>
                  j?.TaxSchemeID === x?.TaxSchemeID &&
                  new Date(j?.Date) <= new Date(newDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })
          : null
      if (NewTax !== null) {
        arr.push({
          InvoiceItemID: x?.InvoiceItemID,
          BillItemID: x?.BillItemID,
          RevenueCode: x?.RevenueCode,
          RevenueName: x?.RevenueName,
          Amount: x?.Amount,
          TaxSchemeID: x?.BillItem?.TaxSchemeID,
          TaxCode: x?.TaxCode,
          TaxRate: NewTax?.Rate,
          TaxAmt: x?.Amount * (NewTax?.Rate / 100),
          DocAmt: x?.Amount + x?.Amount * (NewTax?.Rate / 100),
          CostCentreID: x?.CostCentreID,
          CostCentreCode: x?.CostCentreCode,
          Remark: x?.Remark,
        })
      }
    })
  }

  //For Default Department
  const [defaultCostCentre, loadDefaultCostCentre] = useState(null)
  const [accountType, setAccountType] = useState('')

  const [selectedTax, setSelectedTax] = useState(null)
  const [taxRate, setTaxRate] = useState(0.0)
  const [taxAmt, setTaxAmt] = useState(
    !!menu?.obj && menu?.obj?.InvoiceItemID ? editData?.TaxAmt : 0
  )
  const [totalAmt, setTotalAmt] = useState(0.0)
  const [amount, setAmount] = useState(0.0)
  const [cost, setCost] = useState(
    mode === 'edit' ? editData?.DocAmt - editData?.TaxAmt : 0
  )

  useEffect(() => {
    if (!isNaN(taxAmt)) {
      setValueItem('TaxAmt', taxAmt)
      clearErrorsItem('TaxAmt')
    }
  }, [taxAmt])

  const handleTaxChange = (event, taxEffectiveDate) => {
    const clickedTax = taxScheme?.filter(
      x => x?.TaxSchemeID === event?.target?.value
    )[0]
    setSelectedTax(clickedTax)
    setTaxRate(
      clickedTax.LatestTax === null
        ? clickedTax?.TaxEffective.reduce((a, b) => {
            return new Date(a?.Date) > new Date(b?.Date) ? a : b
          })?.Rate
        : clickedTax?.LatestTax?.Rate
    )
    setTaxAmt(
      new BigNumber(
        clickedTax.LatestTax === null
          ? clickedTax?.TaxEffective.reduce((a, b) => {
              return new Date(a?.Date) > new Date(b?.Date) ? a : b
            })?.Rate
          : clickedTax?.LatestTax?.Rate
      )
        ?.dividedBy(100)
        ?.multipliedBy(cost)
        ?.toNumber()
    )
  }

  const handleAmtChange = event => {
    setCost(event.value)

    if (selectedTax) {
      setTaxAmt(
        new BigNumber(
          selectedTax?.LatestTax === null
            ? selectedTax?.TaxEffective?.reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
            : selectedTax?.LatestTax?.Rate
        )
          .dividedBy(100)
          .multipliedBy(event.value)
          .toNumber()
      )
    }
  }

  const handleTaxAmtChange = event => {
    setTaxAmt(event.value)
  }
  const calculateTotalAmt = () => {
    setTotalAmt(amount + amount * (taxRate / 100))
  }

  const taxEffectiveDate: any =
    !!watch('DocDate') && watch('DocDate').toString() !== 'Invalid Date'
      ? new Date(new Date(watch('DocDate')).getTime() + 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10)
      : null

  const handleBillItemChange = BillItemID => {
    setValueItem('BillItemID', BillItemID)
    let defTax = getBillItem?.filter(x => x?.BillItemID === BillItemID)[0]
      ?.TaxSchemeID
    let selectedTax = taxScheme.find(x => x?.TaxSchemeID === defTax)
    setValueItem('TaxSchemeID', defTax)
    setSelectedTax(selectedTax)
    clearErrorsItem('BillItemID')
    // setCost(event.value)

    const taxAmt = new BigNumber(
      selectedTax?.LatestTax === null
        ? selectedTax?.TaxEffective.reduce((a, b) => {
            return new Date(a.Date) > new Date(b.Date) ? a : b
          })?.Rate
        : selectedTax?.TaxEffective?.filter(
            x => new Date(x?.Date) <= new Date(taxEffectiveDate)
          ).reduce((a, b) => {
            return new Date(a.Date) > new Date(b.Date) ? a : b
          }, 0)?.Rate
    )
      .dividedBy(100)
      .multipliedBy(cost)
      .toNumber()
    setTaxAmt(taxAmt)
    setValueItem('TaxAmt', taxAmt)
  }

  const handleDefaultCostCentre = coaId => {
    const findcoa = getMasterCOA?.find(x => x?.MasterCOAID === coaId)
    setAccountType(findcoa?.AccountType)

    if (
      findcoa?.AccountType === 'ASSET' ||
      findcoa?.AccountType === 'LIABILITY' ||
      findcoa?.AccountType === 'EQUITY'
    ) {
      setValueItem('CostCentreID', defaultCostCentre?.CostCentreID)
      setValueItem('CostCentreName', defaultCostCentre?.Name)
    } else {
      fetchCostCentre({
        variables: {
          CompanyID: CompanyID,
          MasterCOAID: coaId,
          IsLastNode: true,
        },
      })
    }
  }

  /* -------------------------------------------- */
  /*         CREATE & UPDATE MUTATION             */
  /* -------------------------------------------- */

  const [
    createARInvoice,
    { loading: createARInvoiceLoading, called: createARInvoiceCalled },
  ] = useCreateArInvoiceMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ createARInvoice }) => {
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/account-receivable/${CompanyID}/invoice/${createARInvoice?.InvoiceID}/preview`,
          })
        } else {
          history.push({ pathname: `/account-receivable/${CompanyID}/invoice` })
        }
      }, 500)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
    },
  })

  const [
    updateARInvoice,
    { loading: updateARInvoiceLoading, called: updateARInvoiceCalled },
  ] = useUpdateArInvoiceMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: () => {
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/account-receivable/${CompanyID}/invoice/${InvoiceID}/preview`,
          })
        } else {
          history.push({ pathname: `/account-receivable/${CompanyID}/invoice` })
        }
      }, 500)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
    },
  })

  const [
    createArFavoriteInvoice,
    {
      loading: createFavoriteInvoiceLoading,
      error: createFavoriteInvoiceError,
    },
  ] = useCreateArFavoriteInvoiceMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      reset()
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setOpenFavoriteDialog(false)
    },
  })

  const taxCodeName = getTaxScheme?.filter(
    bank => bank?.TaxSchemeID === selectedTax?.TaxSchemeID
  )[0]?.Code

  const taxCodeRate = amtStr(
    getTaxScheme?.filter(
      bank => bank?.TaxSchemeID === selectedTax?.TaxSchemeID
    )[0]?.LatestTax?.Rate
  )

  const costCentreCode =
    watchItem('CostCentreID') !== undefined
      ? getCostCentre.filter(
          x => x?.CostCentreID === watchItem('CostCentreID')
        )[0]?.Name
      : getCostCentre.filter(
          x => x?.CostCentreID === menu?.obj?.CostCentreID
        )[0]?.Name

  const billItemName =
    watchItem('BillItemID') !== undefined
      ? getBillItem.filter(x => x?.BillItemID === watchItem('BillItemID'))[0]
          ?.Name
      : getBillItem.filter(x => x?.BillItemID === menu?.obj?.BillItemID)[0]
          ?.Name

  /* -------------------------------------------- */
  /*                  ATTACHMENT                  */
  /* -------------------------------------------- */

  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  const [
    fetchDocuments,
    { loading: documentLoading, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  /* -------------------------------------------- */
  /*             ONCREATE & ONUPDATE              */
  /* -------------------------------------------- */

  const onCreateInvoiceItem = data => {
    invoiceItemData.push({
      InvoiceItemID: data?.InvoiceItemID,
      BillItemName: billItemName,
      BillItemID: data?.BillItemID,
      CostCentreName: costCentreCode ?? defaultCostCentre?.Name,
      CostCentreID: data?.CostCentreID,
      Amount: parseFloat(amtNumStr(data?.Amount)),
      TaxSchemeID: data?.TaxSchemeID,
      TaxCode: taxCodeName,
      TaxRate: taxCodeRate,
      TaxAmt: data?.TaxAmt,
      DocAmt: getTotalAmt(true, cost, taxAmt, taxRate),
      Remark: data?.Remark,
    })
    setOpenDialog(false)
  }

  const onUpdateInvoiceItem = (data, index) => {
    invoiceItemData[index] = {
      InvoiceItemID: data.InvoiceItemID,
      BillItemName: billItemName,
      BillItemID: data.BillItemID,
      CostCentreName: costCentreCode ?? defaultCostCentre?.Name,
      CostCentreID: data.CostCentreID,
      Amount: parseFloat(amtNumStr(data?.Amount)),
      TaxCode: taxCodeName,
      TaxRate: data?.TaxRate || taxCodeRate,
      TaxSchemeID:
        data?.TaxSchemeID ||
        getBillItem?.filter(x => x?.TaxSchemeID === data?.TaxSchemeID)[0]
          ?.TaxSchemeID,
      TaxAmt: data.TaxAmt,
      DocAmt: getTotalAmt(true, cost, taxAmt, taxRate),
      Remark: data.Remark,
    }

    setOpenDialog(false)
  }

  /* -------------------------------------------- */
  /*                   DELETE                     */
  /* -------------------------------------------- */

  const onDeleteInvoiceItem = index => {
    invoiceItemData.splice(index, 1)
  }

  const [
    deleteARFavoriteInvoice,
    {
      loading: deleteFavoriteInvoiceLoading,
      error: deleteFavoriteInvoiceError,
    },
  ] = useDeleteArFavoriteInvoiceMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOpenFavoriteDeleteDialog(false)
    },
    variables: {
      FavoriteInvoiceID: menuFav?.ID,
    },
  })

  /* -------------------------------------------- */
  /*                  ONSUBMIT                    */
  /* -------------------------------------------- */

  const onSubmit = (data, status) => {
    if (onSub == false) {
      setSub(true)
      if (formMode === 'add') {
        if (
          getDocumentNumberHeader?.filter(x => x?.RefTable === 'AR_Invoice')
            ?.length === 0
        ) {
          setOpenSnackBar(true)
          setSnackBarMsg(SystemMsgs.errorNumberingStructure())
        } else {
          //add the data with GraphQL mutation
          createARInvoice({
            variables: {
              input: {
                InvoiceID: InvoiceID,
                CompanyID: CompanyID,
                DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
                TransactionDate: formatDashDate(
                  new Date(data?.TrxDate)?.toISOString()
                ),
                Remark: data?.Remark,
                ApprovalStatus: statusInput(status),
                Attachment: files,
                DebtorAccountID: data?.DebtorAccountID,
                DocAmt: totalAmount ?? 0,
                Description: data?.Description,
                CreditTerm: term,
                DueDate: new Date(
                  dueDateChecker(data?.DocDate, term)
                ).toISOString(),
                RefNo: data?.RefNo,
                DocAmtBeforeTax: amtBeforeTax ?? 0,
              },
              itemInput: invoiceItemData?.map(x => {
                return {
                  BillItemID: x?.BillItemID,
                  Amount: parseFloat(x?.Amount),
                  TaxSchemeID: x?.TaxSchemeID,
                  TaxRate: parseFloat(x?.TaxRate),
                  TaxAmt: parseFloat(x?.TaxAmt),
                  DocAmt: parseFloat(x?.DocAmt),
                  CostCentreID: x?.CostCentreID,
                  Remark: x?.Remark,
                  Sequence: x?.Sequence,
                }
              }),
            },
            refetchQueries: [
              {
                query: GetArInvoiceDocument,
                variables: {
                  CompanyID: CompanyID,
                  StatusArr: ['ACTIVE'],
                },
              },
              {
                query: DocumentListingDocument,
                variables: {
                  refTable: 'S_Attachment',
                  refID: editData?.InvoiceID,
                },
              },
            ],
          })
        }
      } else if (formMode !== 'add') {
        //update the data
        updateARInvoice({
          variables: {
            input: {
              InvoiceID: InvoiceID,
              CompanyID: CompanyID,
              DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
              TransactionDate: formatDashDate(
                new Date(data?.TrxDate)?.toISOString()
              ),
              Remark: data?.Remark,
              ApprovalStatus: statusInput(status),
              Attachment: files,
              DebtorAccountID: data?.DebtorAccountID,
              DocAmt: totalAmount ?? 0,
              Description: data?.Description,
              CreditTerm: term,
              DueDate: new Date(
                dueDateChecker(data?.DocDate, term)
              ).toISOString(),
              RefNo: data?.RefNo,
              DocAmtBeforeTax: amtBeforeTax ?? 0,
            },
            itemInput: invoiceItemData?.map(x => {
              return {
                BillItemID: x?.BillItemID,
                Amount: parseFloat(x?.Amount),
                TaxSchemeID: x?.TaxSchemeID,
                TaxRate: parseFloat(x?.TaxRate),
                TaxAmt: parseFloat(x?.TaxAmt),
                DocAmt: parseFloat(x?.DocAmt),
                CostCentreID: x?.CostCentreID,
                Remark: x?.Remark,
                Sequence: x?.Sequence,
              }
            }),
          },
          refetchQueries: [
            {
              query: GetArInvoicebyStatusDocument,
              variables: {
                CompanyID: CompanyID,
                StatusArr: ['ACTIVE'],
              },
            },
            {
              query: DocumentListingDocument,
              variables: {
                refTable: 'S_Attachment',
                refID: editData?.InvoiceID,
              },
            },
          ],
        })
      }
    }
  }

  const favRefetchQuery = [
    {
      query: GetArFavoriteInvoiceDocument,
      variables: {
        CompanyID,
        UserID: user?.ID,
      },
    },
  ]

  const onSubmitFavorite = data => {
    const tempFav = {
      Name: data?.Name,
      UserID: user?.ID,
      InvoiceID: !!getValues('InvoiceID') ? getValues('InvoiceID') : null,
      Remark: !!getValues('Remark') ? getValues('Remark') : null,
      DebtorAccountID: !!getValues('DebtorAccountID')
        ? getValues('DebtorAccountID')
        : null,
      Description: !!getValues('Description') ? getValues('Description') : null,
      DocAmt: !!getValues('DocAmt') ? getValues('DocAmt') : 0,
    }
    const invItem: any[] = invoiceItemData
    const tempFavItem = invItem?.map(x => {
      return {
        BillItemID: x?.BillItemID,
        CostCentreID: x?.CostCentreID,
        TaxSchemeID: x?.TaxSchemeID,
        TaxRate: parseFloat(x?.TaxRate),
        TaxAmt: parseFloat(x?.TaxAmt),
        Amount: parseFloat(amtNumStr(x?.Amount)),
        DocAmt: parseFloat(amtNumStr(x?.DocAmt)),
        Sequence: x?.Sequence,
        Remark: x?.Remark,
      }
    })
    createArFavoriteInvoice({
      variables: {
        ARFavoriteInvoiceInput: tempFav,
        ARFavoriteInvoiceItemInput: tempFavItem,
      },
      refetchQueries: favRefetchQuery,
    })
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */
  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      itemArr: invoiceItemData,
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
      itemSuffixID: 'InvoiceItemID',
      itemTableName: 'ARInvoiceItem',
      formMode: formMode,
    })

  const { handlePermDisabled } = usePermissionChecker()

  const createUpdateLoading = editData
    ? updateARInvoiceLoading
    : createARInvoiceLoading
  const createUpdateCalled = editData
    ? updateARInvoiceCalled
    : createARInvoiceCalled

  /* -------------------------------------------- */
  /*                    FOOTER                    */
  /* -------------------------------------------- */

  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => !createUpdateLoading && onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      checkingYearClose1 || checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccReceivableInvoicesDraft,
          }),
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => !createUpdateLoading && onSubmit(data, 'reject'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      !!errors?.DocDate ||
      !!errors?.TrxDate ||
      !!errors?.RefNo ||
      !!errors?.DebtorAccountID ||
      !!errors?.Description ||
      checkingYearClose1 ||
      checkingYearClose2
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.AccReceivableInvoicesUpdate,
          }),
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
      setIsSubmit(true)
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      calcTotal(invoiceItemData) === 0 ||
      calcTotal(invoiceItemData) === undefined ||
      checkingYearClose1 ||
      checkingYearClose2
        ? true
        : false,
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  return (
    <>
      {openPeriodCheckDateLoading && <Loading />}
      {CompanyLoading && <Loading />}
      {billItemLoading && <Loading />}
      {TaxLoading && <Loading />}
      {DebtorAccountLoading && <Loading />}
      {documentLoading && <Loading />}
      {TaxEffectiveDateLoading && <Loading />}
      {CostCentreLoading && <Loading />}
      {deleteFavoriteInvoiceLoading && <Loading />}
      {DocDateValidationLoading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      {FavoriteInvoiceLoading && <Loading />}
      {createFavoriteInvoiceLoading && <Loading />}
      {masterCOALoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          {
            if (hasChanges() === true) {
              setOpenExitConf(true)
            } else {
              history.push({
                pathname: `/account-receivable/${CompanyID}/invoice`,
              })
            }
          }
        }}
        smTitle={'Accounts Receivable'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'Accounts Receivable Main Menu' },
          { name: 'Accounts Receivable Submenu' },
          { name: 'Invoice' },
          { name: 'Invoices', current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              formMode === 'add'
                ? 'New'
                : formMode === 'approve-reject'
                ? 'Approve/Reject'
                : formMode === 'edit'
                ? 'Edit'
                : 'Draft',
            current: true,
          },
        ]}
      />

      <ContentWrapper float>
        <CardContents
          section={{
            header: {
              title: 'Invoice',

              icon:
                formMode === 'add' ? (
                  <FavoriteMenu
                    options={getARFavoriteInvoice}
                    funcLabel={'Add Favorite'}
                    addFavFunc={handleAddFavorite}
                    selectFavFunc={handleSelectFavorite}
                    optionIDName={'FavoriteInvoiceID'}
                    setOpenFavoriteDeleteDialog={setOpenFavoriteDeleteDialog}
                    anchorEl={anchorElFav}
                    setAnchorEl={setAnchorElFav}
                    handleClose={handleCloseFav}
                    handleClickDelete={handleClickFav}
                  />
                ) : null,
            },
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="DocDate"
              label="Invoice Date"
              control={control}
              onChange={(date: Date | null) => {}}
              onAccept={(date: Date | null) => {
                updateNewInvoiceItem(date, taxEffectiveList)
              }}
              format="dd/MM/yyyy"
              value={watch(formMode === 'add' ? new Date() : editData?.DocDate)}
              margin="dense"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData ? editData?.DocDate : new Date()}
              showTodayButton
              className="left"
              disabled={formMode === 'approve-reject'}
              // minDate={
              //   formMode === 'add'
              //     ? DocDateValidationBefore(
              //         getDocumentDateValidation[0]?.MonthsBefore
              //       )
              //     : editData?.DocDate
              // }
              // maxDate={DocDateValidationAfter(
              //   getDocumentDateValidation[0]?.MonthsAfter
              // )}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose1
                  ? 'Financial Period already closed'
                  : errors?.DocDate?.message
              }
              error={errors?.DocDate || checkingYearClose1 ? true : false}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxDate"
              required
              label="Transaction Date"
              control={control}
              format="dd/MM/yyyy"
              margin="dense"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              showTodayButton
              className="right"
              value={new Date()}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
              helperText={
                checkingYearClose2
                  ? 'Financial Period already closed'
                  : errors?.TrxDate?.message
              }
              error={errors?.TrxDate || checkingYearClose2 ? true : false}
            />
          </MuiPickersUtilsProvider>

          <Controller
            as={TextField}
            id="standard-basic"
            name="RefNo"
            label="Reference No."
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
            defaultValue={editData?.RefNo}
            disabled={mode == 'approve-reject'}
          />

          {!DebtorAccountLoading && (
            <Controller
              render={({ value, onChange }) => {
                const defVal = getDebtorAccount?.filter(
                  x => x?.DebtorAccountID === editData?.DebtorAccountID
                )[0]
                return (
                  <Autocomplete
                    options={
                      getDebtorAccount?.sort((a, b) => {
                        return a?.DebtorName?.localeCompare(b?.DebtorName)
                      }) || []
                    }
                    getOptionLabel={option => `${option?.DebtorName}`}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('DebtorAccountID', newValue?.DebtorAccountID)
                      // clearErrors('DebtorAccountID')
                      setTerm(newValue?.CreditTerm)
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.DebtorName}</span>
                    }}
                    defaultValue={defVal}
                    disabled={mode === 'approve-reject'}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.DebtorAccountID?.message}
                            error={errors?.DebtorAccountID ? true : false}
                            label="Debtor Name"
                            style={{ width: '100%' }}
                            margin="dense"
                            required
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Debtor Name"
              name="DebtorAccountID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="dense"
              ref={register}
              helperText={errors?.DebtorAccountID?.message}
              error={errors?.DebtorAccountID ? true : false}
              defaultValue={editData?.DebtorAccountID}
              required
              disabled={mode === 'approve-reject'}
            />
          )}

          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            ref={register}
            defaultValue={editData?.Description}
            disabled={mode === 'approve-reject'}
          />

          {/* <VoiceTextField
            name="Remark"
            mounted={true}
            label="Remark"
            value={audioInput}
            record={record}
            setRecord={setRecord}
            setValue={setValue}
            defaultValue={getValues('Remark')}
            customOnchange
            register={register}
            control={control}
            clearErrors={clearErrors}
            handleCustomOnChange={e => {
              setText(e.target.value)
              handleSingleRemark(e.target.value)
            }}
            // helperTextProps={{
            //   helperText: errors?.Remark?.message,
            //   error: errors?.Remark ? true : false,
            // }}
            // required
          /> */}
          <VoiceTextField
            mounted={true}
            label="Remark"
            name="Remark"
            value={voiceRemark}
            setValue={setValue}
            record={record}
            setRecord={setRecord}
            control={control}
            controllerProps={{
              error: errors?.Remark ? true : false,
              helperText: errors?.Remark?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />

          <div style={{ width: '100%', marginTop: '24px' }}>
            <FileUploadInput
              placeholder={previewFiles.length === 0 ? 'Attachment' : null}
              label={previewFiles.length > 0 ? 'Attachment' : null}
              name="Attachment"
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              accept={
                '.png, .jpg, .jpeg, .gif, .mp4, .avi, .mkv, .mov, .flv, .3gp, application/msword, application/pdf, application/vnd.ms-excel, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
            />
          </div>
        </CardContents>

        <CardContents
          section={{
            header: {
              title: `Invoice Detail (${invoiceItemData?.length})`,
              onClickAction: () => {
                setOpenDialog(true)
                resetMenu()
                reset()
                resetItem()
                setTaxAmt(null)
                setSelectedTax(null)
              },
              icon: (
                <AddIcon
                  htmlColor="white"
                  fontSize="small"
                  style={{
                    width: '20px',
                    height: '20px',
                    margin: '0',
                    background: theme.palette.primary.main,
                    borderRadius: '3px',
                    color: 'rgba(224,234,254,100)',
                    marginTop: '10px',
                    marginRight: '10px',
                  }}
                />
              ),
            },
          }}
        >
          <div className="rm-padding table-wrap ">
            {invoiceItemData === undefined || invoiceItemData?.length === 0 ? (
              <EmptyList
                title="No Record Found"
                subtitle="Add New Record now."
              />
            ) : (
              invoiceItemData?.map((v, index) => {
                return (
                  <>
                    <Grid spacing={1} container className="table-content">
                      <Grid
                        item
                        xs={1}
                        style={{
                          placeSelf: 'start',
                        }}
                      >
                        <span className="xxTitle">{`${index + 1}.`}</span>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        style={{
                          placeSelf: 'start',
                          marginTop: '8px',
                        }}
                      >
                        <div>
                          <div className="xxTitle text-noflow">
                            <span className="desc"> {v?.BillItemName}</span>
                          </div>
                          <div className="desc text-noflow">
                            {v?.CostCentreName}
                          </div>
                          <div className="desc text-noflow">{v?.Remark}</div>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={2}
                        style={{
                          placeSelf: 'start',
                          marginTop: '32px',
                          textAlign: 'right',
                          flexWrap: 'wrap',
                          display: 'flex',
                          justifyContent: isDesktop ? 'center' : 'start',
                        }}
                      >
                        <div>
                          <div className="desc">
                            {` ${v?.TaxCode} | ${v?.TaxRate}%`}
                          </div>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        style={{
                          placeSelf: 'start',
                          marginTop: '8px',
                          marginRight: 'auto',
                          textAlign: 'right',
                          flexWrap: 'wrap',
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                      >
                        <div>
                          <div className="xxTitle highlight-text">
                            {amtStr(v?.DocAmt)}
                          </div>

                          <div className="desc highlight-text">
                            {amtStr(v?.Amount)}
                          </div>
                          <div className="desc highlight-text">
                            {amtStr(v?.TaxAmt)}
                          </div>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={1}
                        style={{
                          marginTop: '0px',
                        }}
                      >
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          aria-controls="menu-list"
                          aria-haspopup="true"
                          onClick={e => {
                            handleClick(e, v?.InvoiceItemID, index, v)
                            setCost(v?.Amount)
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </Grid>
                    </Grid>

                    <Grid spacing={1} container className="table-content">
                      <Grid
                        item
                        xs={1}
                        style={{
                          placeSelf: 'start',
                        }}
                      ></Grid>
                    </Grid>

                    <Menu
                      id="menu-list"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      onClick={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          setEdit(true)
                          setOpenDialog(true)
                          handleBillItemChange(menu?.obj?.BillItemID)
                        }}
                      >
                        <span className="">Edit</span>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          onDeleteInvoiceItem(index)
                        }}
                      >
                        <span className="">Delete</span>
                      </MenuItem>
                    </Menu>
                    <Divider
                      variant="fullWidth"
                      style={{ background: '#E4E4E4' }}
                    ></Divider>
                  </>
                )
              })
            )}
          </div>
        </CardContents>

        <div style={{ marginBottom: '50px' }} />
      </ContentWrapper>

      <AddFavoriteDialog
        openFavoriteDialog={openFavoriteDialog}
        setOpenFavoriteDialog={setOpenFavoriteDialog}
        favRegister={favRegister}
        favControl={favControl}
        favNames={favNames}
        favErrors={favErrors}
        favWatch={favWatch}
        dialogTitle={'Invoice Detail'}
        handleFavSubmit={handleFavSubmit}
        onSubmitFavorite={onSubmitFavorite}
      />

      <DeleteFavoriteDialog
        openFavoriteDeleteDialog={openFavoriteDeleteDialog}
        setOpenFavoriteDeleteDialog={setOpenFavoriteDeleteDialog}
        dialogTitle={'Favorite Invoice Entry'}
        menuFav={menuFav}
        deleteMutation={deleteARFavoriteInvoice}
        favRefetchQuery={favRefetchQuery}
        favID={menuFav?.ID}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account-receivable/${CompanyID}/invoice`)
          localStorage.removeItem('invoice')
          localStorage.removeItem('invoiceItem')
        }}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />

      {footerOptions?.length > 0 ? (
        <Footer options={[...footerOptions]} />
      ) : null}

      <CommonDialog
        fullWidth={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
          reset()
          resetItem()
          setTaxAmt(null)
          setSelectedTax(null)
          setAccountType('')
        }}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <div className="session">
                    <div className="flex session">
                      <div
                        className="dialog-title flex-space"
                        style={{
                          fontSize: '12px',
                          fontWeight: 'bold',
                          color: 'orange',
                        }}
                      >
                        {'Invoice Detail'}
                      </div>
                      <div
                        className="dialog-title right-text"
                        style={{
                          fontSize: '12px',
                          fontWeight: 'bold',
                          color: 'orange',
                        }}
                      >
                        {menu?.obj ? 'Edit' : 'New'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="infoline-content" />
              </div>
            ),
          },
          body: () => (
            <>
              <div className="content-container" style={{ marginTop: '-20px' }}>
                <Controller
                  render={({ onChange, onBlur, value }) => {
                    const defVal = getBillItem?.filter(
                      x => x?.BillItemID === menu?.obj?.BillItemID
                    )[0]

                    return (
                      <Autocomplete
                        options={getBillItem || []}
                        getOptionLabel={option => `${option?.Name}`}
                        fullWidth
                        onChange={(value, newValue: any) => {
                          handleBillItemChange(newValue?.BillItemID)
                          setValueItem('BillItemID', newValue?.BillItemID)
                          setValueItem('CostCentreID', null)
                          handleDefaultCostCentre(newValue?.MasterCOAID)
                          setSelectedBillItem(newValue?.Name)
                        }}
                        defaultValue={defVal}
                        renderInput={(params: any) => {
                          return (
                            <div>
                              <TextField
                                {...params}
                                required
                                label="Bill Item"
                                variant="standard"
                                fullWidth
                                name="BillItemID"
                                value={value}
                                helperText={errorsItem?.BillItemID?.message}
                                error={errorsItem?.BillItemID ? true : false}
                                defaultValue={menu?.obj?.BillItemID || ''}
                              />
                            </div>
                          )
                        }}
                      />
                    )
                  }}
                  name="BillItemID"
                  autoComplete="off"
                  control={controlItem}
                  multiline={true}
                  fullWidth
                  margin="dense"
                  ref={registerItem}
                  required
                  defaultValue={menu?.obj?.BillItemID || ''}
                  helperText={errorsItem?.BillItemID?.message}
                  error={errorsItem?.BillItemID ? true : false}
                />
                {accountType === 'ASSET' ||
                accountType === 'LIABILITY' ||
                accountType === 'EQUITY' ? (
                  <Controller
                    render={({ value, onChange }) => {
                      return (
                        <TextField
                          value={`${defaultCostCentre?.Code} | ${defaultCostCentre?.Name}`}
                          label="Department *"
                          fullWidth
                          disabled
                        />
                      )
                    }}
                    value={defaultCostCentre?.CostCentreID}
                    name="CostCentreID"
                    autoComplete="off"
                    control={controlItem}
                    multiline={true}
                    fullWidth
                    margin="dense"
                    ref={registerItem}
                    required
                    helperText={
                      (watchItem('CostCentreID') === '' ||
                        watchItem('CostCentreID') === undefined) &&
                      errorsItem?.CostCentreID?.message
                    }
                    error={
                      (watchItem('CostCentreID') === '' ||
                        watchItem('CostCentreID') === undefined) &&
                      errorsItem?.CostCentreID
                        ? true
                        : false
                    }
                  />
                ) : (
                  !CostCentreLoading && (
                    <Controller
                      render={({ value, onChange }) => {
                        const defVal = getCostCentre?.filter(
                          x => x?.CostCentreID === menu?.obj?.CostCentreID
                        )[0]
                        return (
                          <Autocomplete
                            options={
                              getCostCentre?.sort((a, b) => {
                                return a?.Code?.localeCompare(b?.Code)
                              }) || []
                            }
                            getOptionLabel={option =>
                              option ? `${option?.Code} | ${option?.Name}` : ''
                            }
                            fullWidth
                            onChange={(value, newValue: any) => {
                              setValueItem(
                                'CostCentreID',
                                newValue?.CostCentreID
                              )
                            }}
                            renderOption={(props, option) => {
                              return (
                                <div>
                                  <div>
                                    <span className="xsTitle">
                                      {props?.Code}
                                    </span>
                                  </div>
                                  <div className="desc">{props?.Name}</div>
                                </div>
                              )
                            }}
                            defaultValue={defVal}
                            renderInput={(params: any) => {
                              return (
                                <div>
                                  <TextField
                                    {...params}
                                    required
                                    label="Department"
                                    variant="standard"
                                    fullWidth
                                    name="CostCentreID"
                                    value={value}
                                    helperText={
                                      (watchItem('CostCentreID') === '' ||
                                        watchItem('CostCentreID') ===
                                          undefined) &&
                                      errorsItem?.CostCentreID?.message
                                    }
                                    error={
                                      (watchItem('CostCentreID') === '' ||
                                        watchItem('CostCentreID') ===
                                          undefined) &&
                                      errorsItem?.CostCentreID
                                        ? true
                                        : false
                                    }
                                    defaultValue={menu?.obj?.CostCentreID || ''}
                                  />
                                </div>
                              )
                            }}
                          />
                        )
                      }}
                      name="CostCentreID"
                      autoComplete="off"
                      control={controlItem}
                      multiline={true}
                      fullWidth
                      margin="dense"
                      ref={registerItem}
                      required
                      defaultValue={menu?.obj?.CostCentreID || ''}
                      helperText={
                        (watchItem('CostCentreID') === '' ||
                          watchItem('CostCentreID') === undefined) &&
                        errorsItem?.CostCentreID?.message
                      }
                      error={
                        (watchItem('CostCentreID') === '' ||
                          watchItem('CostCentreID') === undefined) &&
                        errorsItem?.CostCentreID
                          ? true
                          : false
                      }
                    />
                  )
                )}

                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  thousandSeparator
                  customInput={TextField}
                  id="standard-basic"
                  name="Amount"
                  label="Amount"
                  value={cost}
                  autoComplete="off"
                  control={controlItem}
                  onValueChange={e => {
                    handleAmtChange(e)
                  }}
                  decimalScale={2}
                  fixedDecimalScale
                  margin="dense"
                  required
                  fullWidth
                  helperText={errorsItem?.Amount?.message}
                  error={errorsItem?.Amount ? true : false}
                  ref={registerItem}
                  defaultValue={menu?.obj?.Amount || ''}
                />

                <Grid
                  item
                  xs={6}
                  style={{
                    marginBottom: '5px',
                    paddingRight: '10px',
                  }}
                >
                  <TextField
                    name="TaxSchemeID"
                    label="Tax Code *"
                    value={selectedTax ? `${selectedTax?.Code}` : ''}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    defaultValue={menu?.obj?.TaxSchemeID || ''}
                    className="left"
                    disabled
                    margin="dense"
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    marginBottom: '5px',
                  }}
                >
                  <TextField
                    name="TaxRate"
                    label="Tax Rate %"
                    value={amtStr(taxCodeRate)}
                    disabled
                    // className="right"
                    margin="normal"
                    // ref={register}
                    style={{ marginTop: '5px' }}
                  />
                </Grid>

                <Controller
                  as={TextField}
                  select
                  value={
                    selectedTax
                      ? `${selectedTax?.Code} ${amtStr(
                          selectedTax?.LatestTax?.Rate ??
                            selectedTax?.TaxEffective?.reduce((a, b) => {
                              return new Date(a?.Date) > new Date(b?.Date)
                                ? a
                                : b
                            })?.Rate ??
                            ''
                        )}`
                      : ''
                  }
                  name="TaxSchemeID"
                  autoComplete="off"
                  control={controlItem}
                  defaultValue={menu?.obj?.TaxSchemeID || ''}
                  ref={registerItem}
                  hidden={true}
                  style={{ display: 'none' }}
                />

                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  thousandSeparator
                  customInput={TextField}
                  ref={registerItem}
                  control={controlItem}
                  margin="dense"
                  name="TaxAmt"
                  label="Tax Amount"
                  onValueChange={e => {
                    handleTaxAmtChange(e)
                  }}
                  value={taxAmt}
                  defaultValue={menu?.obj?.TaxAmt ?? taxAmt ?? 0}
                  decimalScale={2}
                  fixedDecimalScale
                  className="left"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                <Controller
                  render={({ value, onChange }) => {
                    return (
                      <TextField
                        label="Doc Amount"
                        value={amtStr(getTotalAmt(true, cost, taxAmt, taxRate))}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        className="right"
                        disabled
                      />
                    )
                  }}
                  disabled
                  margin="dense"
                  defaultValue={menu?.obj?.DocAmt || 0}
                  name="DocAmt"
                  ref={registerItem}
                  control={controlItem}
                />

                <Controller
                  as={TextField}
                  id="standard-basic"
                  name="Remark"
                  label="Remark"
                  autoComplete="off"
                  control={controlItem}
                  fullWidth
                  margin="dense"
                  ref={registerItem}
                  defaultValue={edit ? menu?.obj?.Remark : ''}
                  helperText={errorsItem?.Remark?.message}
                  error={errorsItem?.Remark ? true : false}
                  required
                />
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setOpenDialog(false)
                    setTaxAmt(null)
                    setSelectedTax(null)
                    setAccountType('')
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },

              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    handleSubmitItem(() =>
                      menu?.obj
                        ? onUpdateInvoiceItem(watchItem(), menu?.index)
                        : onCreateInvoiceItem(watchItem())
                    )()
                    reset()
                    resetItem()
                    setAccountType('')
                  },

                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <TooltipAmountFooter data={invoiceItemData} module={'invoice-draft'} />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
